// eslint-disable-next-line import/no-unresolved
import { Modal, Offcanvas } from 'bootstrap'
import Choices from 'choices.js'

import htmx from 'htmx.org'

import axios from 'axios'

import persist from '@alpinejs/persist'
import Alpine from 'alpinejs'

import { productFruits } from 'product-fruits'

// This is here because in search_form_advanced.html we have a script tag that uses bootstrap and Choices
// window.bootstrap = bootstrap
window.htmx = htmx
window.Modal = Modal
window.Offcanvas = Offcanvas
window.Choices = Choices

// Axios is used in a script on the login form
window.axios = axios

// Alpine is used everywhere :)
Alpine.plugin(persist)
window.Alpine = Alpine
Alpine.start()

window.productFruits = productFruits
